import { type RouteHandle } from '@remix-run/server-runtime';
import { type LandingHandle } from './_layout.tsx';
import { cn, useDebounce } from '~/utils/misc.ts';
import { FAQSection } from '~/components/landing/faq.tsx';
import { Card } from '~/components/ui/card.tsx';
import { Icon } from '~/components/ui/icon.tsx';
import React, {
	type ForwardedRef,
	forwardRef,
	useState,
	useRef,
	type FC,
} from 'react';
import { Form, Link, useNavigate } from '@remix-run/react';
import { useIsAuthenticated } from '~/root.tsx';
import { StudentStoriesSection } from '~/components/landing/animated-student-stories.tsx';
import { WhatAreYouWaitingForSection } from '~/components/landing/what-are-you-waiting-for.tsx';
import { z } from 'zod';
import { parseWithZod } from '@conform-to/zod';
import { Button } from '~/components/ui/button.tsx';
import { Calendar } from '~/components/ui/calendar.tsx';
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from '~/components/ui/popover.tsx';
import { FormatDate } from '~/components/format-date.tsx';
import { type DateRange } from 'react-day-picker';
import {
	useAutocompleteArrowsInput,
	useLocationAutocompletePlaces,
	useLocationAutocompletePredictions,
} from '~/utils/use-location-autocomplete.ts';
import { Input } from '~/components/ui/input.tsx';
import { useGoogleMapJsLoader } from '~/utils/use-google-maps-loader.ts';
import { useMutation } from '@tanstack/react-query';
import { postCheckListingsAvailability } from '../resources+/check-listings-availability.tsx';
import { toast } from 'sonner';
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogTitle,
} from '#app/components/ui/dialog.js';
import { ErrorList } from '#app/components/form/forms.js';
import { StatusButton } from '#app/components/ui/status-button.js';
import {
	requestListingsAvailability,
	verifyListingsAvailability,
} from '../resources+/request-listings-availability.tsx';
import {
	InputOTP,
	InputOTPGroup,
	InputOTPSlot,
} from '#app/components/ui/input-otp.js';
import { analytics } from '#app/components/analytics/segment-tracker.client.js';

export const handle = {
	mode: 'guest',
	mainClass: 'flex flex-col items-center pb-8',
} satisfies RouteHandle & LandingHandle;

export default function GuestLandingRoute() {
	return (
		<>
			<div className="relative w-full max-w-[1440px] px-[22px] sm:px-4 md:px-8">
				<div className="flex h-[calc(min(900px,100vh-var(--landing-header-height)))] w-full flex-col items-center pt-40 sm:justify-center sm:pt-0">
					<HeroSection />
				</div>
			</div>
			<HowItWorksSection />
			<StudentStoriesSection />
			<WhatAreYouWaitingForSection />
			<FAQSection />
		</>
	);
}

function HeroSection() {
	return (
		<>
			<section className="flex w-full sm:hidden">
				<div className="w-[340px] rounded-md border-border bg-white pb-4 pl-[14px] pr-8 pt-8 shadow-md">
					<h1 className="text-[34px] font-bold leading-[normal] duration-500 animate-in fade-in slide-in-from-bottom fill-mode-both">
						Find your <br /> perfect sublet
					</h1>
					<div className="h-2"></div>
					<p className="text-lg delay-200 duration-500 animate-in fade-in slide-in-from-bottom fill-mode-both">
						Get personalized matches with <br /> university-verified hosts
					</p>
					<div className="h-8"></div>
					<GuestOnboarding className="delay-300 duration-500 animate-in fade-in slide-in-from-bottom fill-mode-both" />
				</div>
				<img
					src="/img/landing/home.jpeg"
					alt="home"
					className="absolute inset-0 -z-10 h-full w-full object-cover"
				/>
			</section>
			<section className="hidden w-full flex-row items-center justify-center gap-8 sm:flex lg:justify-between lg:overflow-hidden lg:pl-4">
				<div className="relative flex h-max w-fit flex-col items-center pb-64">
					<div
						className={cn(
							'flex flex-col pb-3 text-4xl font-bold sm:text-6xl lg:w-max min-[1064px]:text-7xl',
							'duration-500 animate-in fade-in slide-in-from-bottom fill-mode-both',
						)}
					>
						<h1 className="text-center lg:text-start">
							Find your <br className="hidden lg:inline" /> perfect sublet
						</h1>
					</div>
					<div
						className={cn(
							'flex w-full flex-col pb-6 text-2xl',
							'delay-200 duration-500 animate-in fade-in slide-in-from-bottom fill-mode-both',
						)}
					>
						<h2 className="text-center lg:text-start">
							Get personalized matches with <br className="hidden lg:inline" />{' '}
							university-verified hosts
						</h2>
					</div>
					<GuestOnboarding className="delay-300 duration-500 animate-in fade-in slide-in-from-bottom fill-mode-both" />
				</div>
				<div className="relative hidden flex-row gap-4 self-end overflow-hidden pb-2 pr-12 lg:flex">
					<div className="flex w-80 flex-col gap-4">
						<ExampleListingCard
							title="Colby's Studio"
							subtitle="USC' 26"
							imageUrl="/img/landing/listings/kitchen-1.jpg"
							avatarUrl="/img/landing/host/colby.jpg"
							universityUrl="/img/landing/universities/usc-trojans.png"
						/>
						<ExampleListingCard
							title="Grace's Room"
							subtitle="Northwestern' 24"
							imageUrl="/img/landing/listings/bedroom-1.jpg"
							avatarUrl="/img/landing/host/grace.jpeg"
							universityUrl="/img/landing/universities/northwestern.png"
						/>
						<ExampleListingCard
							title="Skylar's Place"
							subtitle="Columbia' 24"
							imageUrl="/img/landing/listings/kitchen-2.jpg"
							avatarUrl="/img/landing/host/skylar.jpeg"
							universityUrl="/img/landing/universities/columbia-university.png"
						/>
					</div>
					<div className="relative w-80">
						<div className="absolute -bottom-6 right-0 flex rotate-6 flex-col gap-4">
							<ExampleListingCard
								title="Luis' Cozy Apartment"
								subtitle="Northwestern' 25"
								imageUrl="/img/landing/listings/bedroom-3.jpg"
								avatarUrl="/img/landing/host/luis.jpeg"
								universityUrl="/img/landing/universities/northwestern.png"
							/>
							<ExampleListingCard
								title="Leah's House"
								subtitle="Harvard' 25"
								imageUrl="/img/landing/listings/bedroom-2.jpg"
								avatarUrl="/img/landing/host/leah.jpg"
								universityUrl="/img/landing/universities/harvard-university.png"
							/>
							<ExampleListingCard
								title="Ty's Apartment"
								subtitle="Northwestern' 25"
								imageUrl="/img/landing/listings/bedroom-4.jpg"
								avatarUrl="/img/landing/host/ty.jpeg"
								universityUrl="/img/landing/universities/northwestern.png"
							/>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

type ExampleListingCardProps = {
	imageUrl: string;
	title: string;
	subtitle: string;
	universityUrl: string;
	avatarUrl: string;
	className?: string;
};
function ExampleListingCard(props: ExampleListingCardProps) {
	const { title, subtitle, imageUrl, avatarUrl, universityUrl, className } =
		props;
	return (
		<Card className={cn('h-72 w-80 overflow-hidden', className)}>
			<figure className="group relative flex h-full w-full items-center justify-center overflow-hidden">
				<img
					className="h-full w-full bg-gray-200 object-cover transition-transform group-hover:scale-110"
					src={imageUrl}
					alt="example listing"
				/>
				<figcaption className="absolute bottom-0 left-0 right-0 flex items-center gap-3 bg-gradient-to-t from-black/70 to-transparent pb-6 pl-4 pt-6 text-white">
					<div className="relative">
						<img
							src={avatarUrl}
							alt="example listing host"
							className="h-[4.5rem] w-[4.5rem] rounded-full object-cover"
						/>
						<img
							src={universityUrl}
							alt="example listing host"
							className="absolute -bottom-3 -right-3 h-[2.5rem] w-[2.5rem] object-contain"
						/>
					</div>
					<div>
						<h4 className="text-sm font-semibold leading-3">{title}</h4>
						<p className="text-sm leading-3">
							{subtitle}
							<Icon name="blue-checkmark" className="h-6 w-6" />
						</p>
					</div>
				</figcaption>
			</figure>
		</Card>
	);
}

function GuestOnboarding({ className }: { className?: string }) {
	const authenticated = useIsAuthenticated();
	const navigate = useNavigate();
	return (
		<div
			className={cn(
				'mb-6 flex w-full max-w-[620px] flex-col gap-2 sm:mb-0 sm:items-center sm:gap-4',
				className,
			)}
		>
			{authenticated ? (
				<OnboardingButton onClick={() => navigate('/app/listing/new')} />
			) : (
				<OnboardingForm />
			)}
			<div className="flex items-start justify-start gap-6 pl-6 sm:w-full sm:justify-end sm:pl-0">
				<Link
					to="/signup?type=guest-partial"
					prefetch="intent"
					className="text-sm text-gray-500"
				>
					verify now, match later
				</Link>
			</div>
		</div>
	);
}

const OnboardingButton = forwardRef(
	(
		{ onClick }: { onClick?: () => void },
		ref: ForwardedRef<HTMLButtonElement>,
	) => {
		return (
			<button
				ref={ref}
				className={cn(
					'grid w-full grid-cols-[2fr,44px] items-center gap-4 rounded-full border bg-white py-[2px] pl-6 text-center text-base font-semibold shadow-outer-border hover:bg-gray-background/80 hover:delay-0 sm:grid-cols-[2fr,2px,2fr,2px,2fr,44px] sm:justify-items-center md:w-[620px] md:max-w-none',
					'text-gray-500 delay-300 duration-500 animate-in fade-in slide-in-from-bottom fill-mode-both',
				)}
				onClick={onClick}
			>
				<div className="py-2 text-start sm:text-center">Anywhere</div>
				<div className="hidden h-[46px] w-[1px] py-2 sm:block">
					<div className="h-full w-[1px] bg-gray-200"></div>
				</div>
				<div className="hidden py-2 text-center sm:block">Check-in</div>
				<div className="hidden h-[46px] w-[1px] py-2 sm:block">
					<div className="h-full w-[1px] bg-gray-200"></div>
				</div>
				<div className="hidden py-2 text-center sm:block">Check-out</div>
				<Button
					asChild
					variant="gradient"
					className="pointer-events-auto ml-[2px] flex h-[38px] w-[38px] items-center justify-center rounded-full p-0"
				>
					<div>
						<Icon name="magnifying-glass" className="h-6 w-6" />
					</div>
				</Button>
			</button>
		);
	},
);

const howItWorksSlides = [
	<HowItWorksStep
		key="slide-1"
		className="flex-1"
		image={<Icon name="blue-checkmark" className="h-8 w-8" />}
		title="Verify"
		description="We verify that all users are University-affiliated."
	/>,
	<HowItWorksStep
		key="slide-2"
		className="flex-1"
		image={
			<img src="/img/landing/icons/match.png" alt="match" className="h-8 w-8" />
		}
		title="Match"
		description="Share your preferences and we send you matches."
	/>,
	<HowItWorksStep
		key="slide-3"
		className="flex-1"
		image={
			<img
				src="/img/landing/icons/sublet.png"
				alt="sublet"
				className="h-8 w-8"
			/>
		}
		title="Sublet"
		description="Pick the perfect place. It’s never been easier."
	/>,
];

function HowItWorksSection() {
	const scrollContainerRef = useRef<HTMLDivElement | null>(null);
	const [slideIndex, setSlideIndex] = useState(0);
	const handleScroll = useDebounce(
		(e: React.UIEvent<HTMLDivElement, UIEvent>) => {
			const container = scrollContainerRef.current;
			if (!container) return;

			setSlideIndex(
				Math.max(
					Math.min(
						Math.floor(
							(container.scrollLeft / container.scrollWidth) *
								howItWorksSlides.length,
						),
						howItWorksSlides.length - 1,
					),
					0,
				),
			);
		},
		30,
	);

	return (
		<section className="flex w-full items-center justify-center bg-hostU-blue-gradient px-6 py-10 md:px-8 md:py-20">
			<div className="w-full max-w-[1440px] text-white">
				<h3 className="text-2xl font-semibold">How it works</h3>
				<div
					className="hide-scrollbar flex snap-x snap-mandatory gap-6 overflow-x-auto overflow-y-hidden pb-3 pt-4 sm:flex-col sm:overflow-hidden md:flex-row md:gap-10 md:pt-6 lg:gap-14"
					onScroll={handleScroll}
					ref={scrollContainerRef}
				>
					{howItWorksSlides}
				</div>
				<div className="flex items-center justify-center gap-2 sm:hidden">
					{howItWorksSlides.map((_, i) => (
						<div
							key={i}
							className={cn(
								'h-2 w-2 rounded-full bg-white',
								i === slideIndex ? 'opacity-100' : 'opacity-50',
							)}
						/>
					))}
				</div>
			</div>
		</section>
	);
}

type HowItWorksStepProps = {
	image: React.ReactNode;
	title: string;
	description: string;
	className?: string;
};
export function HowItWorksStep(props: HowItWorksStepProps) {
	const { title, description, image, className } = props;

	return (
		<div
			className={cn(
				'flex min-w-full snap-start flex-col gap-2 rounded-lg bg-white px-6 py-12 text-black sm:min-w-0',
				className,
			)}
		>
			<h4 className="flex items-center gap-2 text-xl font-semibold md:text-2xl">
				{image} {title}
			</h4>
			<div className="h-1" />
			<p className="leading-5">{description}</p>
		</div>
	);
}

const onboardingSchema = z.object({
	address: z.string(),
	from: z.coerce.date(),
	to: z.coerce.date(),
});

function OnboardingForm() {
	const formRef = useRef<HTMLFormElement>(null);
	const navigate = useNavigate();

	const [noListingDialogData, setNoListingDialogData] = useState<
		NoListingsDialogProps['requestData'] | null
	>(null);

	const verifyAvailabilityMutation = useMutation({
		mutationKey: ['verify-availability'],
		mutationFn: async (data: z.infer<typeof onboardingSchema>) => {
			const response = await postCheckListingsAvailability(data);

			if (response.status === 'exists') {
				navigate(response.to);
				return;
			}

			if (response.status === 'error') {
				toast.error(response.error);
				return;
			}

			if (response.status === 'no-listings') {
				setNoListingDialogData(response.data);
				analytics?.track('Guest Landing No Listings Found', response.data);
				return;
			}
		},
	});

	return (
		<>
			{!!noListingDialogData && (
				<NoListingsDialog
					requestData={noListingDialogData}
					onOpenChange={open => {
						if (!open) {
							setNoListingDialogData(null);
						}
					}}
				/>
			)}
			<Form
				ref={formRef}
				className="relative min-h-[44px] w-full rounded-full bg-white text-start text-lg font-semibold text-gray-600 shadow-outer-border hover:bg-gray-100"
				onSubmit={e => {
					e.preventDefault();
					if (!formRef.current) {
						return;
					}

					const formData = new FormData(formRef.current);
					const result = parseWithZod(formData, { schema: onboardingSchema });

					if (!formData.has('address')) {
						(
							formRef.current.querySelector('#address-trigger') as HTMLElement
						)?.click();
						return;
					}

					if (!formData.has('from') || !formData.has('to')) {
						(
							formRef.current.querySelector('#dates-trigger') as HTMLElement
						)?.click();
						return;
					}

					if (result.status !== 'success') {
						return;
					}

					verifyAvailabilityMutation.mutate(result.value);
				}}
			>
				<div className="pointer-events-none absolute inset-0 z-20 grid grid-cols-[1fr,44px] items-center sm:grid-cols-[1fr,2fr,44px]">
					<Button
						type="submit"
						variant="gradient"
						disabled={verifyAvailabilityMutation.isPending}
						className="pointer-events-auto ml-[2px] flex h-[38px] w-[38px] items-center justify-center rounded-full p-0 [grid-column:2] sm:[grid-column:3]"
					>
						<Icon name="magnifying-glass" className="h-6 w-6" />
					</Button>
				</div>
				<AddressField />
				<DatesField />
			</Form>
		</>
	);
}

type RequestData = {
	address: string;
	from: Date | string;
	to: Date | string;
};

type NoListingsDialogProps = {
	onOpenChange: (open: boolean) => void;

	requestData: RequestData;
};

type NoListingsDialogSlide =
	| {
			type: 'initial';
			requestData: RequestData;
	  }
	| {
			type: 'form';
			requestData: RequestData;
	  }
	| {
			type: 'verify';
			requestData: RequestData & {
				email: string;
			};
	  }
	| {
			type: 'success';
	  };

export const NoListingsDialog: FC<NoListingsDialogProps> = props => {
	const { requestData, onOpenChange } = props;

	const [slide, setSlide] = useState<NoListingsDialogSlide>({
		type: 'initial',
		requestData,
	});

	return (
		<Dialog
			open={true}
			onOpenChange={open => {
				onOpenChange(open);
			}}
		>
			<DialogContent
				title="No listings found"
				noClose
				className="flex max-w-2xl flex-col items-center justify-center gap-10 p-12 transition-[width] md:w-fit"
			>
				{slide.type === 'initial' && (
					<>
						<div className="h-20 w-20 rounded-lg bg-border/50 p-3 shadow-md">
							<img
								src="/img/icons/rocket.png"
								alt="rocket"
								className="h-full w-full"
							/>
						</div>
						<div className="flex w-full flex-col gap-4">
							<h3 className="text-2xl font-semibold">
								We’re not there just yet!
							</h3>
							<p>
								Unfortunately, we don’t have listings that match your
								preferences at the moment, but we’re always expanding! Submit a
								request below, and we’ll notify you as soon as we’re there!
							</p>
						</div>
						<Button
							variant="green"
							className="px-12 text-lg"
							onClick={() =>
								setSlide({
									type: 'form',
									requestData: slide.requestData,
								})
							}
						>
							Request
						</Button>
					</>
				)}
				{slide.type === 'form' && (
					<NoListingRequestForm
						address={slide.requestData.address}
						from={slide.requestData.from}
						to={slide.requestData.to}
						onSuccess={newData => {
							setSlide({
								type: 'verify',
								requestData: newData,
							});
						}}
					/>
				)}
				{slide.type === 'verify' && (
					<NoListingVerifyForm
						requestData={slide.requestData}
						onSuccess={() => {
							setSlide({
								type: 'success',
							});
						}}
					/>
				)}
				{slide.type === 'success' && (
					<div className="flex flex-row items-center gap-8">
						<div className="size-24 min-w-24 rounded-lg bg-gray-100 p-5">
							<img
								src="/img/icons/request-received.png"
								alt="check"
								className="size-full"
							/>
						</div>
						<div>
							<h3 className="text-2xl font-semibold">Request received!</h3>
							<p className="text-base">
								Thank you for sharing your preferences and joining our student
								community. We’ve got your request and will let you know as soon
								as we have listings that match!
							</p>
						</div>
					</div>
				)}
			</DialogContent>
		</Dialog>
	);
};

const emailSchema = z.string().email();

type NoListingRequest = {
	address: string;
	from: Date | string;
	to: Date | string;
	email: string;
};

type NoListingRequestFormProps = {
	address: string;
	from: Date | string;
	to: Date | string;
	onSuccess: (data: NoListingRequest) => void;
};
export const NoListingRequestForm: FC<NoListingRequestFormProps> = props => {
	const { address, from, to, onSuccess } = props;

	const requestMutation = useMutation({
		mutationKey: ['request-listings-availability'],
		mutationFn: async (email: string) => {
			const validationResult = emailSchema.safeParse(email);
			if (!validationResult.success) {
				return {
					status: 'error' as const,
					error: 'Invalid email',
				};
			}
			const result = await requestListingsAvailability({ email });

			if (result.status === 'error') {
				return { status: 'error' as const, error: result.error };
			}

			if (result.status === 'verification-sent') {
				onSuccess({ email, address, from, to });
				return { status: 'verification-sent' as const };
			}

			return null;
		},
	});

	const [email, setEmail] = useState('');

	const noEmail = !email;

	return (
		<>
			<DialogTitle className="w-full text-start text-2xl">
				Your request
			</DialogTitle>
			<div className="flex max-w-full flex-col gap-2 text-black">
				<div>
					<p className="ml-2 text-lg">Location</p>

					<div className="flex h-[45px] flex-row items-center gap-4 rounded-md border border-border px-2">
						<img
							src="/img/icons/map-marker.png"
							alt="map-marker"
							className="size-8"
						/>
						<span className="overflow-hidden overflow-ellipsis whitespace-nowrap text-lg font-semibold">
							{address}
						</span>
					</div>
				</div>
				<div>
					<p className="ml-2 text-lg">Dates</p>

					<div className="flex h-[45px] flex-row items-center gap-4 rounded-md border border-border px-2">
						<img
							src="/img/icons/calendar.png"
							alt="calendar"
							className="size-8"
						/>
						<span className="overflow-hidden overflow-ellipsis whitespace-nowrap text-lg font-semibold">
							<FormatDate date={from} format="PP" /> -{' '}
							<FormatDate date={to} format="PP" />
						</span>
					</div>
				</div>
				<div>
					<p className="ml-2 text-lg">Email</p>

					<div className="relative flex items-center justify-center">
						<Input
							className={cn(
								'h-[45px] pl-14 text-lg font-semibold transition-colors',
								noEmail &&
									'border-primary font-normal placeholder:text-primary',
							)}
							value={email}
							onChange={e => setEmail(e.target.value)}
							placeholder="ava@u.northwestern.edu"
						/>
						<img
							src="/img/icons/mail.png"
							alt="mail"
							className="absolute left-2 size-8"
						/>
					</div>
					<ErrorList
						errors={
							requestMutation.data?.status === 'error'
								? [requestMutation.data.error]
								: []
						}
					/>
				</div>
			</div>
			<DialogFooter>
				<StatusButton
					variant="default"
					status={requestMutation.isPending ? 'pending' : 'idle'}
					disabled={requestMutation.isPending}
					className="px-12 text-lg"
					onClick={() => requestMutation.mutate(email)}
				>
					Submit
				</StatusButton>
			</DialogFooter>
		</>
	);
};

type NoListingVerifyFormProps = {
	requestData: NoListingRequest & {
		email: string;
	};
	onSuccess: () => void;
};
export const NoListingVerifyForm: FC<NoListingVerifyFormProps> = props => {
	const { requestData, onSuccess } = props;

	const [otp, setOtp] = useState('');

	const verifyMutation = useMutation({
		mutationKey: ['verify-listings-availability'],
		mutationFn: async (otp: string) => {
			const result = await verifyListingsAvailability({
				...requestData,
				code: otp,
				from: requestData.from as Date,
				to: requestData.to as Date,
			});
			if (result.status === 'success') {
				onSuccess();
			}
			return result;
		},
	});

	return (
		<>
			<div>
				<h3 className="text-2xl font-semibold">Verify now</h3>
				<p className="text-base text-gray-500">
					Please enter the six digit code that was sent to you.
				</p>
			</div>
			<div className="min-h-4"></div>
			<InputOTP maxLength={6} value={otp} onChange={setOtp}>
				<InputOTPGroup>
					<InputOTPSlot index={0} />
					<InputOTPSlot index={1} />
					<InputOTPSlot index={2} />
					<InputOTPSlot index={3} />
					<InputOTPSlot index={4} />
					<InputOTPSlot index={5} />
				</InputOTPGroup>
			</InputOTP>
			<div className="min-h-4">
				<ErrorList
					errors={
						verifyMutation.data?.status === 'error'
							? [verifyMutation.data.error]
							: []
					}
				/>
			</div>
			<Button
				variant="gradient"
				className="px-12 text-lg"
				onClick={() => verifyMutation.mutate(otp)}
				disabled={verifyMutation.isPending}
			>
				Verify
			</Button>
		</>
	);
};

function AddressField() {
	const [open, setOpen] = useState(false);
	const [address, setAddress] = useState<string | undefined>();

	return (
		<div className="pointer-events-none absolute inset-0 z-10 grid grid-cols-[1fr,,44px] sm:grid-cols-[1fr,2px,1fr,2px,1fr,44px]">
			<Popover open={open} onOpenChange={setOpen}>
				<PopoverTrigger
					id="address-trigger"
					className="pointer-events-auto justify-items-start gap-2 overflow-hidden pl-6"
				>
					{address && (
						<input
							type="hidden"
							name="address"
							value={address}
							readOnly
							className="hidden"
						/>
					)}
					{address ? (
						<div className="overflow-hidden text-ellipsis whitespace-nowrap text-center text-base">
							{address}
						</div>
					) : (
						<div className="text-start text-base sm:text-center">Anywhere</div>
					)}
				</PopoverTrigger>
				<PopoverContent
					align="start"
					className="flex items-center justify-start gap-6 overflow-hidden rounded-3xl bg-white px-2 py-2 max-sm:w-[70vw] sm:w-[480px]"
				>
					<AddressFieldContent onAddressSelected={setAddress} />
				</PopoverContent>
			</Popover>
		</div>
	);
}

type AddressFieldContentProps = {
	onAddressSelected: (address: string) => void;
};
function AddressFieldContent({ onAddressSelected }: AddressFieldContentProps) {
	const { isLoaded } = useGoogleMapJsLoader();

	if (!isLoaded) {
		return <AddressFieldinput />;
	}

	return <AddressFieldAutocomplete onAddressSelected={onAddressSelected} />;
}

function AddressFieldinput(props: React.InputHTMLAttributes<HTMLInputElement>) {
	return (
		<div className="flex w-full items-center justify-start gap-2 pl-2 pr-2">
			<Icon name="map-pin" className="h-8 w-8 text-gray-500" />
			<Input
				autoFocus
				variant="ghost"
				type="text"
				form="location-autocomplete"
				name="location-autocomplete-internal"
				autoComplete="off"
				className="m-1"
				placeholder="Chicago, IL"
				{...props}
			/>
		</div>
	);
}

function AddressFieldAutocomplete({
	onAddressSelected,
}: AddressFieldContentProps) {
	const { predictions, fetchPredictions, resetPredictions } =
		useLocationAutocompletePredictions();
	const { fetchPlace } = useLocationAutocompletePlaces();

	const [input, setInput] = useState('');
	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		const input = e.currentTarget.value;
		setInput(input);
		fetchPredictions(input);
	};

	const handlePredictionSelected = async (
		prediction: google.maps.places.AutocompletePrediction,
	) => {
		const placeId = prediction.place_id;
		if (!placeId) {
			return;
		}

		const place = await fetchPlace(placeId);
		if (!place || !place.formatted_address) {
			return;
		}

		setInput(place.formatted_address);
		resetPredictions();
		onAddressSelected(place.formatted_address);
	};

	const { autocompleteOnKey, setSelected, selected } =
		useAutocompleteArrowsInput({
			predictions,
			handlePredictionSelected,
		});

	return (
		<div className="flex w-full flex-col items-start gap-2 overflow-hidden">
			<AddressFieldinput
				value={input}
				onChange={handleInputChange}
				onKeyDown={autocompleteOnKey}
			/>
			{!!predictions &&
				predictions.length > 0 &&
				predictions.map((prediction, i) => (
					<button
						key={prediction.place_id}
						className={cn(
							'block w-full select-none overflow-hidden text-ellipsis whitespace-nowrap rounded-sm px-2 py-1.5 text-start text-sm outline-none transition-colors hover:bg-accent hover:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
							selected === i && 'bg-accent text-accent-foreground',
						)}
						onMouseDown={e => {
							e.preventDefault();
							setSelected(i);
							handlePredictionSelected(prediction);
						}}
					>
						{prediction.description}
					</button>
				))}
		</div>
	);
}

function DatesField() {
	const [selected, setSelected] = useState<DateRange>({
		from: undefined,
		to: undefined,
	});

	return (
		<Popover>
			<PopoverTrigger
				id="dates-trigger"
				className="absolute inset-0 grid grid-cols-[1fr,44px] items-center justify-items-center gap-2 pl-4 sm:grid-cols-[1fr,2px,1fr,2px,1fr,44px]"
			>
				{selected.from && (
					<input
						type="hidden"
						name="from"
						value={selected.from.toISOString()}
						readOnly
						className="hidden"
					/>
				)}
				{selected.to && (
					<input
						type="hidden"
						name="to"
						value={selected.to.toISOString()}
						readOnly
						className="hidden"
					/>
				)}
				<div className="pointer-events-none"></div>
				<span className="mt-[2px] h-[38px] w-[1px] bg-gray-200"></span>
				<div className="hidden py-2 text-center text-base sm:block">
					{selected.from ? (
						<FormatDate date={selected.from} format="PP" />
					) : (
						'Check-in'
					)}
				</div>
				<span className="mt-[2px] hidden h-[38px] w-[1px] bg-gray-200 sm:inline"></span>
				<div className="hidden py-2 pr-2 text-center text-base sm:block">
					{selected.to ? (
						<FormatDate date={selected.to} format="PP" />
					) : (
						'Check-out'
					)}
				</div>
			</PopoverTrigger>
			<PopoverContent
				align="start"
				className="mr-20 flex w-auto min-w-[var(--radix-popover-trigger-width)] justify-center gap-2 rounded-3xl p-2 pt-4"
			>
				<div>
					<p className="hidden pb-2 pl-2 font-semibold sm:block">Check-in</p>
					<Calendar
						compact={true}
						mode="range"
						selected={selected}
						className="bg-white p-0"
						onSelect={e => e && setSelected(e)}
					/>
				</div>
				<div className="hidden sm:block">
					<p className="pb-2 pl-2 font-semibold">Check-out</p>
					<Calendar
						compact={true}
						mode="range"
						selected={selected}
						className="bg-white p-0"
						onSelect={e => e && setSelected(e)}
					/>
				</div>
			</PopoverContent>
		</Popover>
	);
}
