import { getBoundingBox } from '#app/utils/bounding-box.js';
import {
	json,
	type SerializeFrom,
	type ActionFunctionArgs,
} from '@remix-run/node';
import { sql } from 'kysely';
import { z } from 'zod';

const dataSchema = z.object({
	address: z.string(),
	from: z.coerce.date(),
	to: z.coerce.date(),
});

export async function postCheckListingsAvailability(
	data: z.infer<typeof dataSchema>,
) {
	const response = await fetch('/resources/check-listings-availability', {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
		},
	});

	return (await response.json()) as SerializeFrom<typeof action>;
}

export async function action({ request, context }: ActionFunctionArgs) {
	const formData = await request.json();
	const data = dataSchema.safeParse(formData);

	if (!data.success) {
		return json(
			{ status: 'error' as const, error: data.error.message },
			{ status: 400 },
		);
	}

	const { address } = data.data;

	const locationResult = await context.geocode.tryGeocodeAddress(address);

	if (!locationResult) {
		return json(
			{ status: 'error' as const, error: 'Could not geocode address' },
			{ status: 400 },
		);
	}

	const { lat, lng } = locationResult;
	const boundingBox = getBoundingBox({
		lat,
		lng,
		radiusInMeters: 50_000,
	});

	const listingsExist = !!(await context.db.$kysely
		.selectFrom('Listing as l')
		.select(eb => eb.lit(true).as('exists'))
		.where(
			sql<boolean>`l.latitude between ${boundingBox.minLat} and ${boundingBox.maxLat}`,
		)
		.where(
			sql<boolean>`l.longitude between ${boundingBox.minLon} and ${boundingBox.maxLon}`,
		)
		.limit(1)
		.executeTakeFirst());

	if (!listingsExist) {
		return json({
			status: 'no-listings' as const,
			data: {
				address,
				from: data.data.from,
				to: data.data.to,
			},
		});
	}

	const jsonData = JSON.stringify(data.data);
	const params = new URLSearchParams();
	params.set('onboarding', jsonData);
	params.set('type', 'guest-full');

	return json({
		status: 'exists' as const,
		to: `/signup?${params.toString()}`,
	});
}
