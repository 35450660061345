import { EmailLayout } from '#app/components/email.js';
import { generateTOTP, verifyTOTP } from '@epic-web/totp';
import {
	type ActionFunctionArgs,
	json,
	type SerializeFrom,
} from '@remix-run/node';
import { namedAction } from 'remix-utils/named-action';
import { z } from 'zod';
import { Text } from '@react-email/components';
import { type FC } from 'react';

const requestSchema = z.object({
	email: z
		.string()
		.email()
		.transform(email => email.toLowerCase()),
});

const verifySchema = z.object({
	code: z.string().min(6).max(6),
	email: z.string().email(),
	address: z.string(),
	from: z.coerce.date(),
	to: z.coerce.date(),
});

export const verificationType = 'listings-availability';

export async function requestListingsAvailability(
	data: z.infer<typeof requestSchema>,
) {
	const resp = await fetch(
		'/resources/request-listings-availability?/request',
		{
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
			},
		},
	);

	return (await resp.json()) as SerializeFrom<typeof action>;
}

export async function verifyListingsAvailability(
	data: z.infer<typeof verifySchema>,
) {
	const resp = await fetch('/resources/request-listings-availability?/verify', {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
		},
	});

	return (await resp.json()) as SerializeFrom<typeof action>;
}

export async function action({ request, context }: ActionFunctionArgs) {
	const data = await request.json();

	return namedAction(request, {
		async request() {
			const requestResult = requestSchema.safeParse(data);

			if (!requestResult.success) {
				return json(
					{
						status: 'error' as const,
						error: 'Invalid email',
					},
					{ status: 400 },
				);
			}

			const { email } = requestResult.data;

			const sixtyMinutesInSeconds = 60 * 60;
			const { otp, secret, algorithm, period, digits } = await generateTOTP({
				algorithm: 'SHA-256',
				period: sixtyMinutesInSeconds,
			});
			// delete old verifications. Users should not have more than one verification
			// of a specific type for a specific target at a time.
			await context.db.verification.deleteMany({
				where: { type: verificationType, target: email },
			});

			await context.db.verification.create({
				data: {
					type: verificationType,
					target: email,
					algorithm,
					secret,
					period,
					digits,
					expiresAt: new Date(Date.now() + period * 1000),
				},
				select: { id: true },
			});

			const response = await context.email.sendEmail({
				to: email,
				subject: `Verify your email on hostU`,
				react: <VerifyEmail otp={otp} />,
			});

			if (context.env.NODE_ENV === 'development') {
				context.logger.info({
					otpCode: otp,
				});
			}

			if (response.status === 'success') {
				return json({ status: 'verification-sent' as const });
			} else {
				return json(
					{
						status: 'error' as const,
						error: 'Failed to send email',
					} as const,
					{ status: 500 },
				);
			}
		},
		async verify() {
			const verifyResult = verifySchema.safeParse(data);

			if (!verifyResult.success) {
				return json(
					{
						status: 'error' as const,
						error: 'Invalid request',
					},
					{ status: 400 },
				);
			}

			const { code, email, address, from, to } = verifyResult.data;

			const verification = await context.db.verification.findFirst({
				where: {
					type: verificationType,
					target: email,
					expiresAt: { gt: new Date() },
				},
				select: { algorithm: true, secret: true, period: true, digits: true },
			});

			if (!verification) {
				return json(
					{
						status: 'error' as const,
						error: 'Invalid code',
					},
					{ status: 400 },
				);
			}

			const isValid = await verifyTOTP({
				otp: code,
				secret: verification.secret,
				algorithm: verification.algorithm,
				period: verification.period,
				window: 0,
			});

			if (!isValid) {
				return json(
					{
						status: 'error' as const,
						error: 'Invalid code',
					},
					{ status: 400 },
				);
			}

			await context.db.verification.deleteMany({
				where: {
					type: verificationType,
					target: email,
				},
			});

			await context.db.listingAvailabilityRequest.upsert({
				where: {
					email: email,
				},
				update: {
					address: address,
					from: from,
					to: to,
				},
				create: {
					email: email,
					address: address,
					from: from,
					to: to,
				},
			});

			return json({ status: 'success' as const });
		},
	});
}

type VerifyEmailProps = {
	otp: string;
};
export const VerifyEmail: FC<VerifyEmailProps> = props => {
	const { otp } = props;
	return (
		<EmailLayout>
			<h1>
				<Text>Welcome to hostU!</Text>
			</h1>
			<p>
				<Text>
					Here's your verification code: <strong>{otp}</strong>
				</Text>
			</p>
		</EmailLayout>
	);
};
